require('../cars-setup');
require('../flights-setup');
require('../hermes-setup');

global.loadWlDeps = function () {
  require('angular-animate');
  require('../../assets/material/angular-material.min');
  require('angular-aria');
}

global.wlAngularDeps = [
  "ngAnimate",
  'ngMaterial',
  'ngAria',
];

global.loadAppDeps = function () {
  loadCarsAppDeps();
  loadFlightsAppDeps();
  loadHermesDeps();
  require('angular-animate');
  require('../../assets/javascripts/whitelabel/bnz/locales');
  require('../../assets/javascripts/whitelabel/bnz/controllers/bnz-controller');
  require('../../assets/javascripts/whitelabel/bnz/services');
  require('../../assets/javascripts/whitelabel/bnz/components');
  require('../../assets/javascripts/booking_app/directives/key-enter');
  require('../../assets/javascripts/booking_app/directives/modal-accessibility-lock');
  require('../../assets/javascripts/booking_app/directives/read-as-one');
}

require('../application');
