import { Modal } from "booking_app/types/modal";
import { modalSettings } from "booking_app/constants/modal-settings";

modalSettings[Modal.HOTEL_ROOM_UNAVAILABLE].content.modalIconCDNPath = "/bnz/icons/fail.svg";
modalSettings[Modal.HOTEL_ROOM_UNAVAILABLE].content.modalHeader = "Room not available";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE].content.modalIconCDNPath = "/bnz/icons/fail.svg";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE].content.modalHeader = "Itinerary no longer available";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE].content.modalContent = "Please reselect your flight(s).";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE].content.resolveButtonText = "Change flights";

modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE].content.modalIconCDNPath = "/bnz/icons/fail.svg";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE].content.modalHeader = "Itinerary no longer available";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE].content.modalContent = "Please reselect your flight(s).";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE].content.resolveButtonText = "Change flights";

modalSettings[Modal.RESEND_CONFIRMATION_SUCCESS].content.modalIconCDNPath = "/bnz/icons/success.svg";
modalSettings[Modal.RESEND_CONFIRMATION_SUCCESS].content.modalHeader = "Done!";
modalSettings[Modal.RESEND_CONFIRMATION_SUCCESS].content.modalBody = "Confirmation email sent";

modalSettings[Modal.CANCEL_BOOKING].content.modalHeader = "Confirm cancellation";
modalSettings[Modal.CANCEL_BOOKING].content.resolveButtonText = "Yes, cancel";
modalSettings[Modal.CANCEL_BOOKING].content.rejectButtonText = "No, keep booking";

modalSettings[Modal.CANCEL_BOOKING_SUCCESS].content.modalIconCDNPath = "/bnz/icons/success.svg";
