import { HermesService } from "booking_app/services/hermes/hermes.service";
import { AppSettings } from "booking_app/values/app-settings";
import "../constants/modal-settings";
import { LoginModalWindowClassOption, MomentDateType, SortType, Tenant, TravelType } from "booking_app/types";
import { SearchSortingService } from "booking_app/services/search-sorting.service";
import { GlobalStateService } from "booking_app/services/global-state.service";

declare var angular: any;

angular.module("BookingApp").config([
  "$ariaProvider", "$routeProvider", "$injector",
  ($ariaProvider, $routeProvider, $injector) => {
    $ariaProvider.config({
      bindRoleForClick: false,
      tabindex: false,
    });

    $routeProvider.when("/:travelType", {
      controller: "SearchCtrl",
      templateUrl: "/html/whitelabel/bnz/search",
      resolve: {
        parseUrl: $injector.get("ParseTrafficParams"),
        RedirectSchemeUrl: $injector.get("RedirectSchemeUrl"),
      },
    });
  },

]);

export class BnzController {

  static $inject = [
    "AppSettings",
    "$rootScope",
    "KaligoConfig",
    "HermesService",
    "GlobalStateService",
    "SearchSortingService",
  ];

  constructor(
    public appSettings: AppSettings,
    public $rootScope: any,
    public kaligoConfig: any,
    public hermesService: HermesService,
    private globalStateService: GlobalStateService,
    private searchSortingService: SearchSortingService,
  ) {
    const selectedPointsPartner = this.$rootScope.pointsPartners.findById(
      this.$rootScope.landingPage.points_partner_id,
    );
    this.$rootScope.selectedCurrency = this.$rootScope.currenciesService.findByCode("NZD");
    this.appSettings.tenant = Tenant.BNZ;
    this.$rootScope.globalState.productType = this.kaligoConfig.PRODUCT_TYPE;
    this.appSettings.roomGuestParameters = {
      labelOnlyRoomCounter: false,
      hasRoomGuestCloser: true,
      labelOnlySummaryText: true,
    };
    this.$rootScope.globalState.showTotalNights = true;
    this.appSettings.carsListBtnLabel = "Select";
    this.appSettings.iconFormat = "svg";
    this.appSettings.hideCheckoutFormPostalCode = true;
    this.appSettings.isTravelBreakdownFlexDirectionDefault = false;
    this.appSettings.hotelDetailsTemplateConfig.breakfastIconUrl = "/bnz/icons/breakfast.svg";
    this.appSettings.hotelDetailsTemplateConfig.noBreakfastIconUrl = "/bnz/icons/nobreakfast.svg";
    this.appSettings.mobileResultPopupConfig = {
      filterIconSource: "bnz/icons/filter-icon.svg",
      sortIconSource: "bnz/icons/sort-icon.svg",
      mapIconSource: "bnz/icons/icon-map.svg",
    };
    this.appSettings.hotelBookingDetailToggles = {
      showEmptyRoomRemarks: true,
      showRoomsLabel: true,
      showNightsLabel: true,
      showGuestsSummary: true,
    };
    this.appSettings.sortSettings.canUseFullPointsOnRedemption = true;
    this.appSettings.sortSettings.prioritisePermittedOptions = true;
    this.appSettings.hasCustomflightSummaryHeaderText = true;
    this.appSettings.flightSegmentArrivalDisplay = true;
    this.appSettings.customFlightLineIconUrl = "/bnz/icons/icn-flightline.png";
    this.appSettings.customFlightIcon = true;
    this.appSettings.multipleAirlineLogoUrl = "/bnz/icons/multipleairlines.svg";
    this.appSettings.iconFormat = "svg";
    this.appSettings.showFlightSelectionState = true;
    this.appSettings.hermesConfig.timeout = 1200;
    this.appSettings.payWithPoints.roundToNearest = 10;
    this.appSettings.payWithPoints.roundDirection = "down";
    this.appSettings.defaultTravelTypeInHomePage = TravelType.FLIGHTS;
    this.appSettings.startCheckInDate = 14;
    this.appSettings.startCheckOutDate = 19;
    this.globalStateService.initState();

    if (this.$rootScope.isUserLoggedIn()) {
      this.hermesService.initHermes();
    }

    this.appSettings.showCancelPolicyOnRedeem = true;
    this.appSettings.loginModalConfig.loginModalWindowClass = LoginModalWindowClassOption.LOGIN_MODAL_VALIDATION_CLASS;
    this.appSettings.logoutUrl = "whitelabel/bnz/logout";
    this.appSettings.alternateSchemeSettings = {
      checkAlternateScheme: true,
      userCustomDataEndpoint: "whitelabel/bnz/user_data",
      redirectScheme: "flybuys",
      redirectSchemeUrl: `${this.$rootScope.rdUrl}/rewards_programs`,
    };
    this.appSettings.defaultAirlineSearchCode = "AKL";
    this.appSettings.materialImplementation = true;
    this.$rootScope.globalState.loginEndpoint = "whitelabel/bnz/login";
    this.$rootScope.globalState.requireLoginOnCheckout = true;
    this.appSettings.checkoutSettings.insufficientPointsModalCheck = true;
    this.appSettings.showCurrencyInFilterSliders = false;
    this.appSettings.defaultCurrencyDisplay = "symbol";
    this.appSettings.pointsCashSliderSettings.sliderRightLabel = "95% Cash";
    this.appSettings.pointsCashSliderSettings.sliderLeftLabel = "0% Cash";
    this.appSettings.pointsCashSliderSettings.sliderLabel = `Combine cash and BNZ Points`;
    this.appSettings.pointsCashSliderSettings.hasCustomSliderLabel = true;
    this.appSettings.hasCouponCode = true;
    this.appSettings.pointsCashSliderSettings.hasCustomSliderLabel = true;
    this.appSettings.pointsCashSliderSettings.incrementPointsSliderCounter = false;
    this.appSettings.pointsCashSliderSettings.ceilingLabelPrefix = "";
    this.appSettings.loginEndpointOpenLoginModal = true;
    this.$rootScope.globalState.useSimpleLoader = true;
    this.appSettings.multiplePointsPartner = true;
    this.appSettings.displayBookWithConfidence = false;
    this.appSettings.customFlightsDateTimeLabel = "ddd, DD MMM YYYY";
    this.appSettings.carsBookingPage.bookingReferenceText = "wl.travel_id";
    this.appSettings.customFlightsTermsAndConditionsModalTitle = "Airfare terms and conditions";
    this.appSettings.carsBookingPage.nonRefundableText = "wl.bnz.cars.cancellation_policy";
    this.appSettings.carDetailsTemplateConfig.carEquipPriceNote = "Prices below are in NZD.";
    this.appSettings.onlyUseAirportCodesinModifySearchMobile = true;
    this.appSettings.dateFormat = MomentDateType.ll;
    this.$rootScope.globalState.serviceEmail = "travel@rewards.bnz.co.nz";
    this.appSettings.stripePaymentIntentsEnabled = true;
    this.appSettings.resultsPageScrollOffset.desktop = 60;
    this.appSettings.mastheadBannerSettings.landing_page_banner.hotels = "lp-banner";
    this.appSettings.mastheadBannerSettings.landing_page_banner.flights = "lp-banner";
    this.appSettings.mastheadBannerSettings.landing_page_banner.cars = "lp-banner";

    // Allow checkout using Amex
    this.appSettings.supportedCards.amex.push("NZD");
    this.appSettings.alwaysShowCancellationFee = true;

    // TODO: REMOVE ON 31ST JULY 2024 ONWARDS
    // month indexed by 0 --> 6 = July
    this.$rootScope.showStarIconOnHeader = this.kaligoConfig.isProduction
      ? Date.now() >= Date.UTC(2024, 6, 30, 16)
      : true;

    if (this.globalStateService.isTravelType(TravelType.HOTELS)) {
      const hotelsEnabledSortingOptions: SortType[] = [SortType.DEAL, SortType.BEST_RATED, SortType.DISTANCE_LOHI,
        SortType.PAY_POINTS_LOHI, SortType.PAY_POINTS_HILO, SortType.PAY_POINTS_CASH_LOHI,
        SortType.PAY_POINTS_CASH_HILO, SortType.RATING_LOHI, SortType.RATING_HILO];
      this.searchSortingService.initializePermittedSortingOptions(hotelsEnabledSortingOptions);
    } else if (this.globalStateService.isTravelType(TravelType.CARS)) {
      const carsEnabledSortingOptions: SortType[] = [SortType.PAY_POINTS_LOHI,
        SortType.PAY_POINTS_HILO, SortType.PAY_POINTS_CASH_LOHI, SortType.PAY_POINTS_CASH_HILO];
      this.searchSortingService.initializeCarsPermittedSortingOptions(carsEnabledSortingOptions);
    }

    this.$rootScope.$on("$routeChangeSuccess", () => {
      if (this.$rootScope.isUserLoggedIn()) {
        this.$rootScope.userDetails.selectedPointsBalance =
          this.$rootScope.userDetails.user.points_balances[0].points_balance;
      }
    });
  }

}

angular.module("BookingApp").controller("BnzController", BnzController);
