import { HeaderUrlLink } from "booking_app/constants/header-url-link";
import { AppSettings } from "booking_app/values/app-settings";
import { CurrentPage, TravelType } from "booking_app/types";

export class HeaderMenuList {
  static $inject = [
    "$rootScope",
    "$window",
    "AppSettings",
    "AppConfig",
  ];

  public headerList: HeaderUrlLink[];

  constructor(
    private $rootScope: any,
    private $window: any,
    private appSettings: AppSettings,
    private appConfig: any,
  ) {
    this.setupHeaderList();
  }

  setupHeaderList(): void {
    const activePagesForTravel: CurrentPage[] = [
      CurrentPage.HOME, CurrentPage.HOTEL_HOME, CurrentPage.FLIGHT_HOME,
      CurrentPage.CAR_HOME, CurrentPage.SEARCH_RESULT, CurrentPage.HOTEL_DETAIL,
      CurrentPage.CHECKOUT, CurrentPage.CONFIRMATION, CurrentPage.CAR_RESULT,
      CurrentPage.CAR_DETAIL, CurrentPage.CAR_CHECKOUT, CurrentPage.CAR_SUCCESS,
      CurrentPage.FLIGHT_RESULT, CurrentPage.FLIGHT_DETAIL,
      CurrentPage.FLIGHT_CHECKOUT, CurrentPage.FLIGHT_SUCCESS,
    ];

    const flybuysProgramId = "82ad5cbe-884f-4cea-864d-d70faca380af";

    const disableFlybuys = this.appConfig?.feature_flags?.disable_bnz_flybuys;

    const redeemLinks = [
      {
        title: "Book travel",
        siteUrl: `${this.$window.location.origin}/flights`,
        travelType: TravelType.FLIGHTS,
        icon: "/bnz/icons/book-travel-airplane.svg",
        active: true,
        pageName: "flight-home",
        activePages: activePagesForTravel,
        redirect: true,
      },
      {
        title: "wl.bnz.gift_cards",
        siteUrl: `${this.$rootScope.rdUrl}/gift_card`,
        icon: "/bnz/icons/e-gift-card.svg",
      },
      {
        title: "wl.bnz.redeem_cash",
        siteUrl: `${this.$rootScope.rdUrl}/redeem_cash`,
        icon: "/bnz/icons/cash-redeem.svg",
      },
      {
        title: "wl.bnz.transfer_partners",
        siteUrl: `${this.$rootScope.rdUrl}/transfer`,
        icon: "/bnz/icons/transfer.svg",
      },
    ];
    const accountLinks = [
      {
        title: "wl.bnz.points_activity",
        siteUrl: `${this.$rootScope.rdUrl}/points_activity`,
        icon: "/bnz/icons/bnz-stars-activity.svg",
      },
      {
        title: "Manage booking",
        siteUrl: `${this.$window.location.origin}/account/booking`,
        icon: "/bnz/icons/manage-booking.svg",
        pageName: "manage-booking",
        activePages: [CurrentPage.MANAGE_BOOKING],
      },
      {
        title: "wl.faq",
        siteUrl: `${this.$rootScope.rdUrl}/faq`,
        icon: "/bnz/icons/faq.svg",
        activePages: [CurrentPage.FAQ],
      },
      {
        title: "Log out",
        siteUrl: `${this.$window.location.origin}/${this.appSettings.logoutUrl}`,
      },
    ];

    if (!disableFlybuys) {
      redeemLinks.push(
        {
          title: "wl.bnz.transfer_flybuys",
          siteUrl: `${this.$rootScope.rdUrl}/transfer/checkout/flybuys/${flybuysProgramId}`,
          icon: "/bnz/icons/share-bnz-stars.svg",
        }
      );

      accountLinks.splice(2, 0,
        {
          title: "wl.bnz.reward_scheme",
          siteUrl: `${this.$rootScope.rdUrl}/rewards_programs`,
          icon: "/bnz/icons/earning-scheme-preference.svg",
        },
      );
    }

    this.headerList = [
      {
        title: "wl.bnz.earn_redeem_miles_hotels",
        active: false,
        requireLoggedIn: false,
        contentType: "link",
        icon: "/bnz/icons/points-star.svg",
        onClickOnlyActive: true,
        activePages: activePagesForTravel,
        links: redeemLinks,
      },
      {
        title: "My Account",
        active: false,
        requireLoggedIn: true,
        contentType: "link",
        icon: "/bnz/icons/my-account.svg",
        onClickOnlyActive: true,
        links: accountLinks,
      },
    ];
  }
}
angular.module("BookingApp").service("HeaderMenuList", HeaderMenuList);
