// HOW TO USE
// add on html element `modal-accessibility-lock`
// can accept string to lock from that element
// locks to modal

// Extra attr options for the directive
// focus-on-show: force focus on the first element on show of the element
// for already visible elements

// last-focusable-element: set this as the last focusable element

// watch-focus-out: catches if it focus out the element and redirect to the supposed next element
declare var angular: any;

angular.module("BookingApp").directive("modalAccessibilityLock", [ "$timeout",
  ($timeout: any) => {
    return {
      restrict: "A",
      link: ($scope, element, attr) => {

        // Variables
        const focusClass: string = attr.modalAccessibilityLock || "modal";
        const lockClass: string = `.${focusClass}`;
        const bindingElement = $(element[0]).closest(lockClass);

        const focusableElements: string =
          "button, a, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])";
        let focusableContent: HTMLElement[];
        let firstFocusableElement: HTMLElement;
        let lastFocusableElement: HTMLElement;
        // Variables End

        // Functions Start
        $scope.tabPressEvent = (event): void => {
          const isTabPressed = event.code === "Tab" || event.keyCode === 9;
          if (!isTabPressed) {
            return;
          }

          if (event.shiftKey || event.keyCode === 16) {
            if (document.activeElement === firstFocusableElement) {
              lastFocusableElement.focus();
              event.preventDefault();
            }
          } else {
            if (document.activeElement === lastFocusableElement) {
              firstFocusableElement.focus();
              event.preventDefault();
            }
          }
        };

        $scope.createBindEvent = (): void => {
          // unbind bind
          bindingElement.unbind("keydown", $scope.tabPressEvent);
          bindingElement.bind("keydown", $scope.tabPressEvent);
        };

        $scope.buildFocusableBindings = (): void => {
          $timeout(() => {
            focusableContent = bindingElement.querySelectorAll(focusableElements)
              .filter((index, item) => {
                // focusable content should not return hidden elements
                if (attr.focusOnShow !== undefined) {
                  const style = window.getComputedStyle(item);
                  return style.display !== "none";
                } else {
                  return item.offsetWidth > 0 && item.offsetHeight > 0;
                }
              } );

            firstFocusableElement = focusableContent[0];
            firstFocusableElement.focus();
            lastFocusableElement = $scope.getLastFocusableElement(focusableContent);
            $scope.createBindEvent();
          }, 200);
        };
        // Functions End

        // Gets the last focusable element
        $scope.getLastFocusableElement = (content: any[]) => {
          if (!focusableContent) {
            return firstFocusableElement;
          } else if (attr.lastFocusElement) {
            return content.filter(attr.lastFocusElement)[0];
          } else {
            return content[focusableContent.length - 1];
          }
        };
        // function end

        // Main Initialisation
        if (bindingElement.hasClass(focusClass) || bindingElement.length >= 1) {
          $scope.buildFocusableBindings();
        }
        // Main Initialisation End

        if (attr.focusOnShow) {
          attr.$observe("focusOnShow", (newValue, _) => {
            if (newValue) {
              $timeout(() => {
                $scope.buildFocusableBindings();
              }, 500);
            }
          });
        }

        if (attr.watchFocusOut) {
          bindingElement.bind("focusout", () => {
            $timeout(() => {
              if (!bindingElement[0].contains(document.activeElement)) {
                lastFocusableElement.focus();
              }
            }, 200);
          });
        }
      },
    };
  },
]);
