// how to use
// add on html element read-as-one accepts string to be read
// Adds aria-label and add index to added directive
// all children element that are div, span and tabindex=-1 will have aria-hidden added

declare var angular: any;

angular.module("BookingApp")
  .directive("readAsOne", [
    "$rootScope",
    "$timeout",
    (
      $rootScope: any,
      $timeout: any,
    ) => {
      return {
        restrict: "A",
        link: ($scope: any, element: any, attr: any) => {
          const ariaLabel: string = attr.readAsOne;
          const focusElement: HTMLElement = element[0];
          const nonFocusableElements: string =
            "div, span, [tabindex='-1'], img";
          focusElement.setAttribute("tabindex", "0");
          focusElement.setAttribute("role", "text");
          focusElement.setAttribute("aria-label", ariaLabel);
          $scope.hideFocusToChildren = () => {
            const nonFocusableContent = focusElement.querySelectorAll(nonFocusableElements);
            nonFocusableContent.forEach((nonFocusableElement) =>  {
              nonFocusableElement.setAttribute("aria-hidden", "true");
            });
          };

          $timeout(() => {
            $scope.hideFocusToChildren();
          }, 1000);
        },
      };
    },
  ]);
