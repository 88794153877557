const keyEnter = [];

angular.module("BookingApp").directive("keyEnter", () => {
  return (scope, elem, attr) => {
    elem.on("keydown keypress", (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        scope.$apply(() => scope.$eval(attr.keyEnter, { $event: e }));
      }
    });

    return;
  };
});

